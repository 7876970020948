import React from 'react';

const ContactModal = ({ closeFunc, isLoading, status }) => {
  return (
    <div className='absolute left-0 top-0 mx-auto flex h-full w-full flex-col items-center justify-center gap-y-4 rounded-tl-lg rounded-bl-lg bg-myOrange p-4'>
      {!isLoading ? (
        <>
          <span className='text-center font-bold text-white'>
            {status
              ? 'Dziękuję za wysłanie zapytania. Skontaktuje się z Tobą w najbliższym możliwym terminie, aby ustalić szczegóły współpracy.'
              : 'Nie udało się wysłać maila. Spróbuj za chwilę.'}
          </span>
          <button
            className='border border-white p-4 font-bold text-white'
            onClick={() => closeFunc(false)}
          >
            Zamknij
          </button>
        </>
      ) : (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='h-12 w-12 animate-spin text-white'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99'
          />
        </svg>
      )}
    </div>
  );
};

export default ContactModal;
