import React, { useState, useEffect } from 'react';
import Intro from '../components/intro/Intro';
import { graphql } from 'gatsby';
import Divider from '../components/divider/divider';
import Wrapper from '../components/wrapper/wrapper';
import { PhoneIcon, MailIcon } from '@heroicons/react/outline';
import IndexLayout from '../layouts';
import Header from '../components/headers/headers';
import { useForm } from 'react-hook-form';
import ContactModal from '../components/modal/modal';

const Kontakt = ({ data }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [submitMail, setSubmitMail] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);

  const onSubmit = (data) => {
    setShowModal((showModal) => true);
    setisLoading((isLoading) => true);
    fetch('https://stromich.eu/php/app.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.text())
      .then((text) => {
        if (text === 'Success') {
          console.log(text);
          setSubmitMail((submitMail) => true);
          reset();
        } else {
          console.log(text);
          console.log('Nie udało się wysłać maila!');
          setisLoading((isLoading) => false);
          setModalStatus((modalStatus) => false);
        }
      })
      .catch((error) => {
        setisLoading((isLoading) => false);
        setModalStatus((modalStatus) => false);
      });
  };

  useEffect(() => {
    if (submitMail) {
      setisLoading((isLoading) => false);
      setModalStatus((modalStatus) => true);
    }
  }, [submitMail]);

  return (
    <IndexLayout>
      <Intro image={data.file.childImageSharp.gatsbyImageData} />
      <Divider />
      <Wrapper className='my-8 flex flex-col'>
        <Header size='h1'>Kontakt</Header>
        <div className='grid grid-cols-1 rounded-lg bg-white shadow-[0_0_30px_-15px_rgba(0,0,0,0.3)] lg:grid-cols-3'>
          <div className='relative col-span-1 flex flex-col gap-y-6 p-6 lg:col-span-2'>
            {showModal && (
              <ContactModal
                closeFunc={setShowModal}
                status={modalStatus}
                isLoading={isLoading}
              />
            )}
            <h2 className='text-2xl font-semibold'>Wyślij wiadomość</h2>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className='col-span-1 grid grid-cols-1 justify-center gap-x-4 gap-y-2 sm:grid-cols-2 lg:col-span-2'
            >
              <div className='flex flex-col'>
                <input
                  type='text'
                  placeholder='Imię *'
                  className={`contactInput ${
                    errors.name?.type === 'required' && 'border-red-500'
                  }`}
                  {...register('name', { required: true })}
                />
              </div>
              <div className='flex flex-col'>
                <input
                  type='text'
                  placeholder='Nazwisko'
                  className='contactInput'
                  {...register('surname')}
                />
                <span className='h-4 px-2 text-sm text-red-500'></span>
              </div>
              <div className='flex flex-col'>
                <input
                  type='tel'
                  placeholder='Telefon w formacie 000-000-000'
                  className='contactInput'
                  {...register('phone')}
                />
                <span className='h-4 px-2 text-sm text-red-500'></span>
              </div>
              <div className='flex flex-col'>
                <input
                  type='mail'
                  placeholder='E-mail *'
                  className={`contactInput ${
                    errors.mail?.type === 'required' && 'border-red-500'
                  }`}
                  {...register('mail', {
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  })}
                />
                <span className='h-4 px-2 text-sm text-red-500'>
                  {errors.mail?.type === 'required' &&
                    'Podaj poprawny adres e-mail'}
                </span>
              </div>
              <div className='flex flex-col sm:col-span-2'>
                <textarea
                  type='text'
                  placeholder='Jak mogę pomóc? *'
                  className={`contactInput sm:col-span-2 ${
                    errors.message?.type === 'required' && 'border-red-500'
                  }`}
                  {...register('message', { required: true, min: 20 })}
                />
                {/* <span className='h-4 px-2 text-sm text-red-500'>
                  {errors.message?.type === 'required' && 'Pole wymagane'}
                </span> */}
              </div>
              <span className='text-sm text-red-500'>* Pola wymagane</span>
              <div className='flex justify-center sm:col-span-2'>
                <input
                  type='submit'
                  value='Wyślij'
                  className='cursor-pointer rounded-md bg-myOrange px-4 py-3 font-bold  text-white'
                />
              </div>
            </form>
          </div>

          <div className='flex flex-col gap-y-4 bg-gray-600 p-6 text-center text-white lg:rounded-r-lg lg:text-left'>
            <h2 className='text-2xl font-semibold'>Kontakt</h2>
            <address className='not-italic'>
              Dom i Biuro - Twoje Meble <br />
              Sebastian Stromich
              <br />
              NIP: 669 109 50 34
            </address>
            <address className='not-italic'>
              Adres pracowni: <br />
              ul. Lniana 7
              <br />
              75-950 Koszalin
            </address>
            <span className='flex justify-center gap-2 lg:justify-start'>
              <PhoneIcon className='h-6 w-6' />
              <span>605 743 953</span>
            </span>
            <span className='flex justify-center gap-2 lg:justify-start'>
              <MailIcon className='h-6 w-6' />
              <span>sebastian@stromich.eu</span>
            </span>
          </div>
        </div>
      </Wrapper>
    </IndexLayout>
  );
};

export const query = graphql`
  {
    file(name: { eq: "contact-intro" }) {
      id
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`;

export default Kontakt;

export function Head() {
  return (
    <>
      <meta charSet='UTF-8' />
      <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />
      <meta
        name='description'
        content='Meble biurowe, kuchenne, szafy, zabudowy. Wszystko na wymiar. Koszalin i okolice. Ponad 25 lat doświadczenia'
      />
      <title>Stromich - meble na wymiar. Kontakt.</title>
    </>
  );
}
